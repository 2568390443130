import styles from './style.module.scss';

export default function index() {
  return (
    <div className={styles.footer}>
      <a aria-label="Chat on WhatsApp" href="https://wa.me/201021020620">Whatsapps</a>
      <a  aria-label="Check Our Instagram" href='https://www.instagram.com/barkartoursegypt/' target='__blank'>Instagram</a>
      <a  aria-label="Check Our Facebook" href='https://www.facebook.com/barkartoursegypt/' target='__blank'>Facebook</a>
      <a  aria-label="Check Our Tiktok"  href='https://www.tiktok.com/@barkartoursegypt?lang=en' target='__blank'>Tiktok</a>
    </div>
  )
}
