import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import { motion, AnimatePresence } from 'framer-motion';
import Nav from './nav';
import { Link } from 'react-router-dom';
import { RiArrowDownSLine } from 'react-icons/ri';
import Magnetic from '../utils/common/Magnetic';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '../components/authContext/AuthContext';
import { useTranslation } from 'react-i18next';

const Navbar = ({ navOpen, setNavOpen, language, setLanguage, setShouldReload, setLanguageExpanded, languageExpanded }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [languageOpen, setLanguageOpen] = useState(false);
    const languageToggleLabel = languageOpen ? "Close Language Dropdown" : "Open Language Dropdown";
    const { user } = useUserAuth();

    const toggleLanguage = () => {
        setLanguageOpen(!languageOpen);
    };
    useEffect(() => {
        i18next.changeLanguage(language);
    }, [language]);
    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage);
        setShouldReload(true);
    };
    const handleEnglishClick = () => {
        handleLanguageChange('en');
    };
    const handleSpanishClick = () => {
        handleLanguageChange('sp');
    };
    const handleFrenchClick = () => {
        handleLanguageChange('fr');
    };
    const handleArabicClick = () => {
        handleLanguageChange('ar');
    };

    const handleRussianClick = () => {
        handleLanguageChange('ru');
    };
    const handlePortClick = () => {
        handleLanguageChange('pr');
    };

    useEffect(() => {
        if (navOpen) {
            document.documentElement.style.setProperty('--navButton-color', 'white');
        }
        return () => {
            document.documentElement.style.removeProperty('--navButton-color');
        };
    }, [navOpen]);

    const navigationItems = [
        { label: user ? t('navbar__admin') : '', link: '/admin' },
        { label: t('navbar__tours'), link: '/Tours' },
        { label: t('navbar__destinations'), link: '/destinations' },
        { label: t('navbar__faqs'), link: '/faqs' },
        { label: t('navbar__book-tour'), link: '/book-tour', className: 'navbar__btn' },
    ];

    useEffect(() => {
        setNavOpen(false); // Close the navbar when the location changes
    }, [location, setNavOpen]);

    return (
        <>
            <header className="header">
                <nav className="header__container">
                    <div className="navbar__left">
                        <Link to="/">
                            {window.innerWidth > 459 ? <h1>Barkar Tours Egyp𓋹</h1> : <h1>Barkar Tours 𓋹</h1>}
                        </Link>
                    </div>
                    <div className="navbar__right">
                        <button onClick={toggleLanguage}
                            aria-label={languageToggleLabel}
                            aria-expanded={languageOpen}>
                            {language}
                            <RiArrowDownSLine />
                            <AnimatePresence mode="wait">
                                {languageOpen && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -100 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 100 }}
                                        className="languages"
                                        aria-label={navOpen ? "Close Navigation Menu" : "Open Navigation Menu"}
                                    >
                                        <motion.div className="languages__container">
                                            <ul>
                                                <li onClick={handleEnglishClick} aria-label="English">
                                                    EN
                                                </li>
                                                <li onClick={handleArabicClick} aria-label="Arabic">
                                                    AR
                                                </li>
                                                <li onClick={handleFrenchClick} aria-label="French">
                                                    FR
                                                </li>
                                                <li onClick={handleSpanishClick} aria-label="Spanish">
                                                    SP
                                                </li>
                                                <li onClick={handleRussianClick} aria-label="Russian">
                                                    RU
                                                </li>
                                                <li onClick={handlePortClick} aria-label="Porteuguese">
                                                    PR
                                                </li>
                                            </ul>
                                        </motion.div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </button>
                        <nav className="navbar__elements" aria-label={navOpen ? "Close Navigation Menu" : "Open Navigation Menu"}>
                            <ul>
                                {navigationItems.map((item, index) => (
                                    <Magnetic key={index}>
                                        <li>
                                            <Link to={item.link} className={item.className} aria-label={item.label}>
                                                {item.label}
                                            </Link>
                                        </li>
                                    </Magnetic>
                                ))}
                            </ul>
                        </nav>

                        <button onClick={() => setNavOpen(!navOpen)} className="el" aria-label="Toggle Navigation">
                            <div className={`burger ${navOpen ? 'burgerActive' : ''}`}></div>
                        </button>
                    </div>
                </nav>
                <AnimatePresence mode="wait">
                    {navOpen && <Nav user={user} />}
                </AnimatePresence>
            </header>
        </>
    );
};

export default Navbar;
