import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";

// Fetch tours from a given path
export const fetchToursFromCollection = async (path) => {
    const collectionRef = collection(db, path);
    const querySnapshot = await getDocs(collectionRef);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

// Fetch sub-tours for each main tour
export const fetchSubToursForMainTours = async (mainToursList) => {
    return Promise.all(
        mainToursList.map(async (mainTour) => {
            const subTours = await fetchToursFromCollection(`tours/${mainTour.id}/tours`);
            return { mainTour, subTours };
        })
    );
};
