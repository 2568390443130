import React from 'react'
import { Link } from 'react-router-dom'
import { IoStarSharp } from 'react-icons/io5'
import Magnetic from '../../utils/common/Magnetic'
import { useTranslation } from 'react-i18next'
import "./SubTours.scss"

const SubTours = ({ subTour, mainTour, generateSlugId, generateSlug }) => {
    const { t } = useTranslation()

    return (
        <Link className="tours__cards" to={`/tours/${generateSlugId(mainTour?.id)}/tours/${generateSlug(subTour.title)}`}>
            <div className="tours__card">
                <div className="tours__card__img">
                    <img src={subTour.mainImage} alt={subTour.title} fill="true" 
                        loading="lazy"
                        width="100%"
                        height="100%"
                    />
                    {subTour.hotOffer &&
                        <div className="tours__card__img__hot-offer">
                            <Magnetic>
                                <p>{t("toursPage__filter__hotOffer")}</p>
                            </Magnetic>
                        </div>
                    }

                </div>
                <div className="tours__card__info">
                    <h3>{subTour?.title?.slice(0, 50)}...</h3>
                    <p>{subTour?.subtitle?.slice(0, 300)}</p>
                </div>
                <div className="tours__card__book-price">
                    <div className="price__details-upper">
                        <h4>{t("tours__startsFrom")}<span> ${subTour.price}</span></h4>
                        <div className="stars">
                            <IoStarSharp />
                            <IoStarSharp />
                            <IoStarSharp />
                            <IoStarSharp />
                            <IoStarSharp />
                        </div>
                    </div>
                    <Magnetic>
                        <div>
                            <div>
                                <p className="navbar__btn">
                                    {t("hero__booknow")}
                                </p>
                            </div>
                        </div>
                    </Magnetic>
                </div>
            </div>
        </Link>
    )
}

export default SubTours