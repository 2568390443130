// Sorting function for tours
export const sortTours = (tours, order) => {
    return tours.sort((a, b) => {
        let indexA = order.indexOf(a.id);
        let indexB = order.indexOf(b.id);
        indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
        indexB = indexB === -1 ? Number.MAX_VALUE : indexB;
        return indexA - indexB;
    });
};
