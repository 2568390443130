import styles from './style.module.scss';
import Rounded from '../../utils/common/RoundedButton';
import { useRef } from 'react';
import { useScroll, motion, useTransform } from 'framer-motion';
import Magnetic from "../../utils/common/Magnetic"
import { Link } from 'react-router-dom';

const Footer = () => {
    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start end", "end end"]
    })
    const x = useTransform(scrollYProgress, [0, 1], [0, 100])
    const rotate = useTransform(scrollYProgress, [0, 1], [180, 90])
    return (
        <motion.footer ref={container} className={styles.contact}>
            <div className={styles.body}>
                <div className={styles.title}>
                    <span>
                        <div className={styles.imageContainer}>
                        </div>
                        <h2>Let's organize your</h2>
                    </span>
                    <h2> next trip together</h2>
                    <motion.div style={{ x }} className={styles.buttonContainer}>
                        <Link to="book-tour">
                            <Rounded backgroundColor={"var(--accent-color-darker)"} className={styles.button}>
                                <p>Get in touch</p>
                            </Rounded>
                        </Link>
                    </motion.div>
                    <motion.svg style={{ rotate, scale: 2 }} width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 8.5C8.27614 8.5 8.5 8.27614 8.5 8L8.5 3.5C8.5 3.22386 8.27614 3 8 3C7.72386 3 7.5 3.22386 7.5 3.5V7.5H3.5C3.22386 7.5 3 7.72386 3 8C3 8.27614 3.22386 8.5 3.5 8.5L8 8.5ZM0.646447 1.35355L7.64645 8.35355L8.35355 7.64645L1.35355 0.646447L0.646447 1.35355Z" fill="white" />
                    </motion.svg>
                </div>
                <nav className={styles.nav}>
                    <Rounded>
                        <a href="mailto:contact@barkartoursegypt.com">contact@barkartoursegypt.com</a>
                    </Rounded>
                    <Rounded>
                        <a href="tel:+201021020620">+201021020620</a>
                    </Rounded>
                    <Rounded>
                        <a href="tel:+201090684357">+201090684357</a>
                    </Rounded>
                </nav>
                <div className={styles.info}>
                    <div className='info__left'>
                        <span>
                            <p>Barkar Tours Egypt 2023 © All Rights Reserved</p>
                        </span>
                        <span>
                            <h3>Made By: </h3>
                            <a href='https://cairo-studio.com/' target='__blank' rel="noopener noreferrer" style={{ color: "#FB2D2D" }}>Cairo Studio</a>
                        </span>
                    </div>
                    <div>
                        <span>
                            <h3>socials</h3>
                            <Magnetic>
                                <a aria-label="Chat on WhatsApp" href="https://wa.me/201021020620">Whatsapps</a>
                            </Magnetic>
                        </span>
                        <Magnetic>
                            <a aria-label="Check Our Instagram" href='https://www.instagram.com/barkartoursegypt/' target='__blank'>Instagram</a>
                        </Magnetic>
                        <Magnetic>
                            <a aria-label="Check Our Facebook" href='https://www.facebook.com/barkartoursegypt/' target='__blank'>Facebook</a>
                        </Magnetic>
                        <Magnetic>
                            <a aria-label="Check Our Tiktok" href='https://www.tiktok.com/@barkartoursegypt?lang=en' target='__blank'>Tiktok</a>
                        </Magnetic>
                    </div>
                </div>
            </div>
        </motion.footer>
    )
}

export default Footer