import React, { useState } from 'react';
import styles from './style.module.scss';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { menuSlide } from '../anim';
import Link from './Link';
import Curve from './Curve';
import Footer from './Footer';

const navItems2 = [
  {
    title: "navbar__home",
    href: "/",
  },
  {
    title: "navbar__tours",
    href: "/tours",
  },
  {
    title: "navbar__destinations",
    href: "/destinations",
  },
  {
    title: "navbar__faqs",
    href: "/faqs",
  },
  {
    title: "navbar__book-tour",
    href: "/book-tour",
  },
];

export default function Menu({user}) {
  const location = useLocation();
  const [selectedIndicator, setSelectedIndicator] = useState(location.pathname);

  return (
    <motion.div variants={menuSlide} initial="initial" animate="enter" exit="exit" className={styles.menu}>
      <div className={styles.body}>
        <div onMouseLeave={() => { setSelectedIndicator(location.pathname) }} className={styles.nav}>
          {navItems2.map((data, index) => (
            <Link key={index} data={{ ...data, index }} isActive={selectedIndicator === data.href} setSelectedIndicator={setSelectedIndicator} />
          ))
          }
          {user && ( // if user is logged in
            <Link data={{ title: "admin", href: "/admin" }} isActive={selectedIndicator === "/admin"} setSelectedIndicator={setSelectedIndicator} />
          )}
        </div>
        <Footer />
      </div>
      <Curve />
    </motion.div>
  );
}
