import React, { useRef, useState, useEffect } from 'react';
import './HomeGallery.scss';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const HomeGallery = ({ mainTours }) => {
    const galleryRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const generateSlugId = (id) => {
        return id.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
    }

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - galleryRef.current.offsetLeft);
        setScrollLeft(galleryRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - galleryRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the multiplier for smoother or faster scrolling
        galleryRef.current.scrollLeft = scrollLeft - walk;
    };

    useEffect(() => {
        // Smooth scroll animation using GSAP
        const scrollConfig = {
            ease: 'power3.inOut', // Easing function for smooth animation
            duration: 0.5,
        };

        if (galleryRef.current) {
            gsap.to(galleryRef.current, {
                scrollLeft: 0,
                scrollTrigger: {
                    trigger: galleryRef.current,
                    start: 'top top',
                    end: 'bottom bottom',
                    toggleActions: 'play none reverse none',
                },
                ...scrollConfig,
            });
        }
    }, []);

    return (
        <div
            className="hero__categories"
            ref={galleryRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
        >
            <div className="hero__categories__container">
                {mainTours.map((mainTour) => (
                    <div className="hero__categories__container__item" key={mainTour.id}>
                        <Link to={`/destinations/${generateSlugId(mainTour.id)}`}>
                            <img src={mainTour.imageURL} alt={mainTour.tourDesc} loading="lazy" width="100%" height="100%"/>
                            <h2>{mainTour.id}</h2>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeGallery;
