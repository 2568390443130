
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyCu-hFa9USWtGJzusAp0JylUEWWguDvId4",
    authDomain: "barkar-c05ee.firebaseapp.com",
    projectId: "barkar-c05ee",
    storageBucket: "barkar-c05ee.appspot.com",
    messagingSenderId: "602799102696",
    appId: "1:602799102696:web:46e3d6c449af1fbaba475d",
    measurementId: "G-5EKQFLZGDS"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const storage = getStorage(app);
