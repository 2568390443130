import React, { useEffect, useState, useCallback } from 'react';
import Hero from '../../components/hero/Hero'
import Tours from '../../components/tours/Tours'
import "./HomePage.scss"
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import HomeGallery from '../../components/homeGallery/HomeGallery'
import { fetchToursFromCollection, fetchSubToursForMainTours } from '../../services/tourServices';
import { sortTours } from '../../utils/sortTours';
import Loading from '../../utils/loading/Loading.tsx';
import { useInView } from 'react-intersection-observer';


const HomePage = ({ mainTours, setMainTours, subTours, setSubTours }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [toursInViewRef, toursInView] = useInView({ triggerOnce: true, threshold: 0.1 });
    const { t } = useTranslation();


    const fetchToursData = useCallback(async () => {
        // Fetching logic
        let mainToursList = await fetchToursFromCollection('tours');
        const tourOrder = ["Cairo Tours", "Giza Tours", "Luxor Tours", "Aswan Tours", "Alexandria Tours", "Sinai Tours"];
        mainToursList = sortTours(mainToursList, tourOrder);
        const subToursList = await fetchSubToursForMainTours(mainToursList);

        sessionStorage.setItem('mainToursHomePage', JSON.stringify(mainToursList));
        sessionStorage.setItem('subToursHomePage', JSON.stringify(subToursList));

        return { mainToursList, subToursList };
    }, []);

    useEffect(() => {
        const initializeTours = async () => {
            const savedMainTours = sessionStorage.getItem('mainToursHomePage');
            const savedSubTours = sessionStorage.getItem('subToursHomePage');

            let mainToursData = savedMainTours ? JSON.parse(savedMainTours) : [];
            let subToursData = savedSubTours ? JSON.parse(savedSubTours) : [];

            // Check if data is incomplete or missing
            if (mainToursData.length < 6 || subToursData.length < mainToursData.length) {
                const fetchedData = await fetchToursData();
                mainToursData = fetchedData.mainToursList;
                subToursData = fetchedData.subToursList;
            }

            setMainTours(mainToursData);
            setSubTours(subToursData);
            setIsLoading(false);
        };

        initializeTours();
    }, [fetchToursData, setMainTours, setSubTours]);




    if (!mainTours || !subTours) {
        return <Loading height={100} />; // Or some other loading state
    }


    return (
        <>
            <Helmet>
                <title>{t("brand__title")} - {t("exploreExcitingTours")}</title>
                <meta name="description" content={t("mainPage__desc")} />
                <meta property="og:title" content="Barkar Tours Egypt" />
                <meta property="og:description" content="Explore exciting tours and travel experiences with Barkar Tours Egypt. Discover a wide range of destinations and book your dream vacation in Egypt with us." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/barkar-tours.appspot.com/o/images%2Fbarkar-tours-egypt.jpg?alt=media&token=3b7b2b6a-7b9a-4b9a-8b0a-4b8b0a4b9a8b" />
                <meta property="twitter:title" content="Barkar Tours Egypt" />
                <meta property="twitter:description" content="Explore exciting tours and travel experiences with Barkar Tours Egypt. Discover a wide range of destinations and book your dream vacation in Egypt with us." />
                <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/barkar-tours.appspot.com/o/images%2Fbarkar-tours-egypt.jpg?alt=media&token=3b7b2b6a-7b9a-4b9a-8b0a-4b8b0a4b9a8b" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://barkartoursegypt.com/" />
                <meta name="twitter:site" content="@barkartours" />
                <meta name="twitter:creator" content="@barkartours" />
                <link rel="canonical" href="https://barkartoursegypt.com/" />
                <meta property="og:site_name" content="Barkar Tours Egypt" />
                <meta name="twitter:image:alt" content="Barkar Tours Egypt" />
                <meta name="robots" content="index, follow" />
                <meta name="googlebot" content="index, follow" />
            </Helmet>
            <motion.main className='homePage' initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 100 }} transition={{ duration: 0.5 }}>
                <Hero />
                <HomeGallery mainTours={mainTours} subTours={subTours} />
                <div ref={toursInViewRef}>
                    {toursInView && <Tours mainTours={mainTours} subTours={subTours} isLoading={isLoading} />}
                </div>
            </motion.main>
        </>
    )
}
export default HomePage