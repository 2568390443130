import React from "react";
import "./Tours.scss";
import { Link } from 'react-router-dom';
import Loading from "../../utils/loading/Loading.tsx";
import SubTours from "../subTours/SubTours";

const Tours = ({ mainTours, subTours, isLoading }) => {
    const subToursMap = {};
    subTours.forEach(item => {
        subToursMap[item.mainTour.id] = item.subTours;
    });

    if (subTours.length === 0 || isLoading) {
        <Loading height={100} />
    }
    const generateSlug = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
    };

    const generateSlugId = (id) => {
        return id.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
    }


    return (
        <section className='tours'>
            {mainTours.map((mainTour, mainTourIndex) => (
                <div className="main-tour-item" key={`mainTour-${mainTourIndex}`}>
                    <div className="selected-main-tour-info">
                        <Link to={{ pathname: `/destinations/${generateSlugId(mainTour.id)}` }}>
                            <h2>{mainTour.id}</h2>
                        </Link>
                        <div className="tours__info">
                            <p>{mainTour.tourDesc}</p>
                        </div>
                    </div>
                    <div className="tours__container-lower">
                        {subToursMap[mainTour.id]?.map((subTour, subTourIndex) => (
                            <SubTours subTour={subTour} subTourIndex={`${mainTour.id}-${subTourIndex}`} mainTour={mainTour} generateSlugId={generateSlugId} generateSlug={generateSlug} key={`subTour-${mainTour.id}-${subTourIndex}`}    />
                        ))}
                    </div>
                </div>
            ))}
        </section>
    );
}

export default Tours;
