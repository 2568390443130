import React from 'react';
import { useInView } from 'react-intersection-observer';
import HeroVideo from "../../assets/FDownloader.net-632102721630968-(1080p).mp4"
import HeroPoster from "../../assets/heroPoster.webp"
import { useTranslation } from "react-i18next";
import "./Hero.scss"

const Hero = () => {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5 // Adjust as needed
    });

    return (
        <div className="hero" ref={ref}>
            <div className="hero-img">
                {inView && (
                    <video src={HeroVideo} alt="" autoPlay loop muted playsInline poster={HeroPoster} />
                )}
                <div className="hero-text">
                    <h2>{t("hero__title")}</h2>
                </div>
            </div>
        </div>
    );
}

export default Hero;
