import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './navbar/Navbar';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Lenis from '@studio-freight/lenis'
import Routers from './routers/Routers';
import Footer from "./components/contact/index"
import Preloader from './components/Preloader';
import i18next from 'i18next';
import WhatsappIcon from './components/whatsappIcon/index.tsx';

function App() {
  const location = useLocation();
  const [navOpen, setNavOpen] = useState(false)
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const smallerDimension = window.innerWidth < 1200;
  const hasAnimationShown = sessionStorage.getItem('hasAnimationShown');
  const [language, setLanguage] = useState(i18next.language);
  const [languageExpanded, setLanguageExpanded] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  const [mainTours, setMainTours] = useState([]);
  const [subTours, setSubTours] = useState([]);

  useEffect(() => {
    if (shouldReload) {
      window.location.reload();
      setShouldReload(false);
    }
  }, [shouldReload]);
  useEffect(() => {
    if (!smallerDimension) {
      const lenis = new Lenis({
        el: document.querySelector('[data-scroll-container]'),
        touchDirection: "vertical",
        duration: 2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        direction: "vertical",
        gestureDirection: "vertical",
        smooth: true,
        smoothTouch: true,
        touchMultiplier: 2,
      });
      const raf = (time) => {
        lenis.raf(time);
        requestAnimationFrame(raf);
      };
      const resize = () => {
        setDimension({ width: window.innerWidth, height: window.innerHeight });
      };
      window.addEventListener("resize", resize);
      requestAnimationFrame(raf);
      resize();
      return () => {
        window.removeEventListener("resize", resize);
      };
    }
  }, [smallerDimension]);
  return (
    <div className="App">
      {!hasAnimationShown ? (<Preloader setMainTours={setMainTours} setSubTours={setSubTours} setShouldReload={setShouldReload} />) : (null)}
      <Navbar navOpen={navOpen} setNavOpen={setNavOpen} shouldReload={shouldReload} setShouldReload={setShouldReload} language={language} setLanguage={setLanguage} languageExpanded={languageExpanded} setLanguageExpanded={setLanguageExpanded} />
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route path='/*' element={<Routers navOpen={navOpen} setMainTours={setMainTours} setSubTours={setSubTours} mainTours={mainTours} subTours={subTours} />} />
        </Routes>
      </AnimatePresence>
      <WhatsappIcon />
      <Footer />
    </div>
  );
}
export default App;