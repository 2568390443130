import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import NotFound from '../pages/notFound/NotFound';
import HomePage from '../pages/home/HomePage';
import Loading from "../utils/loading/Loading.tsx"
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../components/authContext/AuthContext';
const ToursPage = lazy(() => import('../pages/toursPage/ToursPage'));
const ToursDetails = lazy(() => import('../pages/toursDetails/ToursDetails'));
const Faqs = lazy(() => import('../components/faqs/Faqs'));
const Login = lazy(() => import('../admin/login/Login'));
const NewItem = lazy(() => import('../admin/newitem/NewItem'));
const Edit = lazy(() => import('../admin/edit/Edit'));
const Admin = lazy(() => import('../admin/dashboard/Dashboard'))
const EditSection = lazy(() => import('../admin/editSections/EditSections'))
const NewSection = lazy(() => import('../admin/newSection/NewSection'))
const ForgotPassword = lazy(() => import('../admin/forgotPassword/ForgotPassword'))
const BookTour = lazy(() => import('../pages/bookTour/BookTour'))
const Destinations = lazy(() => import('../pages/destinations/Destinations'))
const DestinationsTours = lazy(() => import('../pages/destinationsTours/DestinationsTours'))

const Routers = ({ navOpen, setMainTours, setSubTours, mainTours, subTours}) => {
    const { user } = useUserAuth();
    const location = useLocation();


    const routeConfig = [
        {
            path: '/',
            element: <HomePage key={location.pathname} navOpen={navOpen} setMainTours={setMainTours} setSubTours={setSubTours}  mainTours={mainTours} subTours={subTours} />,
        },
        { path: '/tours', element: <ToursPage /> },
        { path: '/login', element: <Login /> },
        { path: '/faqs', element: <Faqs /> },
        { path: '/destinations', element: <Destinations /> },
        { path: '/destinations', element: <Destinations /> },
        { path: '/destinations/:slugId', element: <DestinationsTours /> },
        { path: '/tours/:slugId/tours/:slug', element: <ToursDetails /> },
        { path: '/passwordreset', element: <ForgotPassword /> },
        { path: '/book-tour', element: <BookTour /> },
        { path: "/admin", element: user ? <Admin /> : <Navigate to="/login" replace /> },
        { path: "/admin/edit/:id/:title", element: user ? <Edit /> : <Navigate to="/login" replace /> },
        { path: "/admin/editSections/:id", element: user ? <EditSection /> : <Navigate to="/login" replace /> },
        { path: "/admin/newSection", element: user ? <NewSection /> : <Navigate to="/login" replace /> },
        { path: "/admin/newItem/:mainTourId", element: user ? <NewItem /> : <Navigate to="/login" replace /> },
        { path: '*', element: <NotFound /> },
    ];

    const renderedRoutes = useRoutes(routeConfig);

    return (
        <>
            <Suspense fallback={<Loading height={100} />}>
                {renderedRoutes}
            </Suspense>
        </>
    );
};

export default Routers;
